.c-near {
    &__link {
        display: block;

        &:hover {
            color: $red;
            text-decoration: none;
        }
    }

    &__img {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__status {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($black, .7);
        color: $white;
        font-size: $font-size-lg;
        font-weight: 700;
    }

    &__heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: $g-sm 0 $o-xs;
    }

    &__title {
        margin-right: $g-sm;
        font-size: 1.8rem;
        font-weight: 700;
    }

    &__distance {
        margin-top: 1px;
    }

    &__route {
        opacity: .5;
    }

    &--dark {
        .c-near {
            &__link {
                color: $white;

                &:hover {
                    color: $red;
                }
            }

            &__img {
                box-shadow: 0 1px 3px $black;
            }

            &__distance {
                color: $white;
            }

            &__route {
                color: $white;
            }
        }
    }
}

.c-add-location {
    &__link {
        display: block;
        position: relative;
        background: $white;

        &-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &-title {
            position: relative;
            padding-top: 55px;
            font-size: $font-size-base;
            font-weight: 700;
            color: $gray-900;
            text-align: center;
            line-height: 1;

            &:before, &:after {
                content: '';
                display: block;
                width: 2px;
                height: 40px;
                position: absolute;
                top: 0;
                left: 50%;
                margin-left: -1px;
                background-color: $black;
                transition: background $transition;
            }

            &:after {
                transform: rotate(90deg);
            }
        }

        &:before {
            content: '';
            display: block;
            padding-bottom: 56.25%;
        }

        &:hover {
            .c-add-location__link-title {
                &:before, &:after {
                    background-color: $red;
                }
            }
        }
    }

    &__description {
        margin-top: $g-sm;
        color: $white;
        font-size: 1.8rem;
        font-weight: 700;
    }
}

@include media-breakpoint-up(md) {

    .c-near {
        &__status {
            font-size: $h4-font-size;
        }
    }

    .c-add-location {
        &__link {
            &-title {
                font-size: 2.4rem;
            }
        }
    }

}
