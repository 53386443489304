.s-content {
    display: inline-block;
    width: 100%;

    h1, h2 {
        font-size: $h4-font-size;
        margin-top: $g;
        margin-bottom: $g;
    }

    h3 {
        font-size: $h5-font-size;
        margin-top: $g;
        margin-bottom: $g;
    }

    h4 {
        font-size: $h6-font-size;
        margin-top: $g;
        margin-bottom: $g;
    }

    h5 {
        font-size: $h6-font-size - .2;
        margin-top: $g;
        margin-bottom: $g;
    }

    h6 {
        font-size: $font-size-sm;
        margin-top: $g;
        margin-bottom: $g;
    }

    img {
        display: block;
        margin: $g auto;
    }
}

.c-float {
    &__img {
        margin: $g auto;

        img {
            margin: 0 !important;
        }
    }
}

.s-delivery-content {
    font-size: 1.8rem;

    h2 {
        font-size: 2.6rem;
        margin: $o 0;
        text-transform: uppercase;
        font-weight: 500;
    }

    p {
        margin-bottom: 25px;
    }
}

@include media-breakpoint-up(md) {

    .s-content {
        h1, h2 {
            font-size: $h2-font-size - 1;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        h3 {
            font-size: $h3-font-size - .5;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        h4 {
            font-size: $h4-font-size;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        h5 {
            font-size: $h5-font-size;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        h6 {
            font-size: $h6-font-size;
            margin-top: 50px;
            margin-bottom: 50px;
        }

        img {
            margin: 60px auto;
        }
    }

    .c-float {
        &__img {
            margin: 60px 0;
        }

        &__content {
            width: 500px;
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-float {
        display: flex;
        padding: $o-lg 0;

        &__img {
            flex-grow: 1;
            margin: 0 0 0 calc(50% - 50vw);
        }

        &__content {
            flex-shrink: 0;
            width: $column;
            margin-left: $o-lg;
        }

        &--reverse {
            flex-direction: row-reverse;

            .c-float {
                &__img {
                    margin-left: 0;
                    margin-right: calc(50% - 50vw);
                }

                &__content {
                    margin-left: 0;
                    margin-right: $o-lg;
                }
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-float {
        &__img {
            margin-left: calc(#{$sidebar} / 2 + 50% - 50vw);
        }

        &--reverse {
            .c-float {
                &__img {
                    margin-right: calc(#{$sidebar} / 2 + 50% - 50vw);
                }
            }
        }
    }

}

@include media-breakpoint-up(laptop) {

    .s-content {
        h1, h2 {
            font-size: $h1-font-size;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        h3 {
            font-size: $h2-font-size;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        h4 {
            font-size: $h3-font-size;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        h5 {
            font-size: $h4-font-size;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        h6 {
            font-size: $h5-font-size;
            margin-top: 60px;
            margin-bottom: 60px;
        }

        img {
            margin: 100px auto;
        }
    }

    .c-float {
        padding: 120px 0;

        &__content {
            margin-left: $gutter;
        }

        &--reverse {
            .c-float {
                &__content {
                    margin-right: $gutter;
                }
            }
        }
    }

}
