.btn-primary {
    @include button-variant($black, $black, $white, $black, $gray-200, $black);
    box-shadow: none !important;
}

.btn-secondary {
    @include button-variant($white, $white, $red, $red, darken($red, 5), darken($red, 5));
    box-shadow: none !important;
}

.btn {
    padding-bottom: $btn-padding-y - 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 1.3rem;
}

.btn-lg {
    padding-bottom: $btn-padding-y-lg - 1;
    font-size: 1.5rem;
}

@include media-breakpoint-up(md) {

    .btn {
        padding: 21px 38px;
        font-size: 1.4rem;
    }

    .btn-lg {
        padding: 29px 48px;
        font-size: 1.8rem;
    }

}
