.c-heading {
    overflow: hidden;

    &__inner {
        padding: $o-lg 0;
    }

    &__title {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 3.6rem;
    }

    &--lg {
        position: relative;
        height: 50vh;
        min-height: 320px;
        margin-bottom: $g;

        .c-heading {
            &__inner {
                position: absolute;
                left: 0;
                right: 0;
                bottom: $o;
                padding: 0;
            }

            &__title {
                color: $white;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &.is-bg {
            background-size: cover;
            background-position: center;

            img {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

@include media-breakpoint-up(sm) {

    .c-heading {
        &__title {
            font-size: $h2-font-size;
        }
    }

}

@include media-breakpoint-up(md) {

    .c-heading {
        &__inner {
            padding: 100px 0;
        }

        &__title {
            font-size: $h1-font-size;
        }

        &--lg {
            margin-bottom: $o-lg;

            .c-heading {
                &__inner {
                    bottom: $g-lg;
                }
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-heading {
        &--lg {
            height: calc(100vh - 90px);
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-heading {
        &--lg {
            height: 100vh;
        }
    }

}

@include media-breakpoint-up(hd) {
    
    .c-heading {
        &__title {
            font-size: $display2-size - 1;
        }
    }
    
}

@include media-breakpoint-up(laptop) {

    .c-heading {
        &__inner {
            padding: 140px 0;
        }

        &--lg {
            margin-bottom: $gutter;

            .c-heading {
                &__inner {
                    bottom: $gutter;
                }
            }
        }
    }

}

@include media-breakpoint-up(fullhd) {

    .c-heading {
        &__title {
            font-size: $display1-size;
        }
    }

}