.c-item {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 5px $o-sm $o-sm;

    &__photo {
        position: relative;
        display: block;
        margin-bottom: $g-sm;

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 8px;
        font-size: 2.5rem;
        font-weight: 700;
        line-height: $headings-line-height;
    }

    &__amount {
        margin-bottom: 8px;
        font-size: 1.3rem;
        opacity: .5;
    }

    &__description {
        margin-bottom: $g-sm;
        font-size: 1.4rem;
        opacity: .5;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &__footer {
        margin-top: auto;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__price {
        font-weight: 700;
        font-size: 2.5rem;
    }

    &:hover {
        text-decoration: none;
    }

    .no-touchevents & {
        transition: background-color .5s ease-in-out, box-shadow .5s ease-in-out;

        &:hover {
            background-color: $white;
            box-shadow: 0 20px 30px rgba($black, .05);
        }
    }
}

.c-cart-item {
    display: flex;
    align-items: flex-start;
    position: relative;

    &__photo {
        position: relative;
        flex-shrink: 0;
        width: 70px;
        margin-right: $o-sm;

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &__main {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        position: relative;
    }

    &__heading {
        width: 100%;
    }

    &__title {
        padding-right: 25px;
        margin-bottom: 5px;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: $headings-line-height;
    }

    &__amount {
        margin-bottom: 5px;
        font-size: 1.4rem;
        opacity: .5;
    }

    &__remove {
        position: absolute;
        top: -4px;
        right: -5px;
        padding: 5px;
        background: none;
        border: none;
        color: $delivery;
        cursor: pointer;

        svg {
            display: block;
            fill: currentColor;
        }

        &:hover {
            color: $red;
        }
    }

    &__count {
        margin-right: $g-sm;
    }

    &__price {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
    }

    & + & {
        margin-top: $g-sm;
    }
}

.c-order-item {
    display: flex;
    align-items: flex-start;

    &__photo {
        position: relative;
        flex-shrink: 0;
        width: 40px;
        margin-right: $o-sm;

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }
    }

    &__main {
        display: flex;
        align-items: center;
        align-self: center;
        flex-grow: 1;
    }

    &__heading {
        flex-grow: 1;
    }

    &__title {
        margin-bottom: 5px;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: $headings-line-height;
    }

    &__amount {
        font-size: 1.4rem;
        opacity: .5;
    }

   &__price {
        flex-shrink: 0;
        margin-left: $o-sm;
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
    }
}

@include media-breakpoint-up(md) {

    .c-cart-item--lg {
        .c-cart-item {
            &__title {
                font-size: 2rem;
            }

            &__amount {
                margin-bottom: 10px;
                font-size: 1.6rem;
            }

            &__price {
                font-size: 2rem;
            }

            &__remove {
                top: -3px;

                svg {
                    width: 14px;
                    height: 16px;
                }
            }
        }

        .c-number {
           width: 106px;

            &__btn {
               height: 28px;
               width: 28px;
            }

            .form-control {
               width: 50px;
               font-size: 2rem;
               height: 28px;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-cart-item--lg {
        .c-cart-item {
            &__main {
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: flex-start;
                padding-right: $g;
                align-self: center;
            }

            &__heading {
                width: auto;
                flex: 1 1 57%;
                margin-right: $o;
            }

            &__title {
                padding-right: 0;
            }

            &__count {
                flex: 1 0 25%;
            }

            &__price {
                margin-top: 3px;
                flex: 1 0 15%;
            }

            &__amount {
                margin-bottom: 0;
            }

            &__remove {
                top: -2px;
            }
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-item {
        &__price {
            font-size: 2.8rem;
        }
    }

    .c-cart-item--lg {
        .c-cart-item {
            &__photo {
                width: 80px;
                margin-right: $g-sm;
            }

            &__title {
                font-size: 2.4rem;
            }

            &__amount {
                font-size: 1.8rem;
            }

            &__price {
                margin-top: 2px;
                font-size: 2.4rem;
                flex-basis: 17%;
            }

            &__remove {
                top: -1px;
            }
        }
    }

}