.c-banner {
    display: flex;
    height: 60px;
    background-color: $white;
    z-index: 10;

    &__inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        top: 60px;
    }

    &__burger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: normal;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: $g-sm;
        transform: translateY(-50%);
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        color: $body-color;

        &-item {
            flex-shrink: 0;
            height: 3px;
            width: 100%;
            margin: 2px 0;
            background-color: currentColor;
            border-radius: 3px;
            transition: $transition;

            .is-sidebar & {
                &:first-child {
                    transform: translateY(4px) rotate(-45deg);
                }

                &:last-child {
                    transform: translateY(-3px) rotate(45deg);
                }
            }
        }
    }

    &__logo {
        display: block;
        width: 80px;
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        padding: $g-sm $g 0;
        margin-bottom: $sidebar-spacer;

        &-list {
            @include list;
            text-align: center;
        }

        &-item {
            &:not(:last-child) {
                margin-bottom: $g-sm;
            }
        }

        &-link {
            position: relative;
            display: block;
            font-size: $font-size-sm;
            font-weight: 700;

            &:hover {
                text-decoration: none;
                opacity: .5;
            }

            .is-active & {
                position: relative;
                color: $white;
                opacity: 1;

                &:before {
                    content: '';
                    display: block;
                    width: 216px;
                    height: 80px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    background: url(../img/brash2.png) no-repeat;
                    background-size: contain;
                    z-index: -1;
                }
            }

            svg {
                position: absolute;
                top: 2px;
                right: calc(100% + 5px);
                fill: currentColor;
            }
        }
    }

    &__partners {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto $sidebar-spacer;
        font-size: $h4-font-size;
        font-weight: 900;
        line-height: 1;
        text-transform: uppercase;

        svg {
            margin-right: 15px;
            width: 45px;
            height: 42px;
            fill: currentColor;
        }

        &:hover {
            text-decoration: none;
            color: $red;
        }
    }

    &__blocks {
        &-item {
            flex-shrink: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: $o-xs 80px $o-xs $g;
            height: 100px;
            background-color: $gray-200;
            border: none;
            font-weight: 700;
            line-height: 1.2;
            text-align: left;
            cursor: pointer;

            &:hover {
                text-decoration: none;
                background-color: darken($gray-200, 4%);
            }

            &.is-active {
                background-color: $gray-800;
                color: $white;
            }

            &--locations {
                .is-locations & {
                    background-color: $gray-800;
                    color: $white;
                }
            }

            &--delivery {
                background-color: $delivery;

                &:hover {
                    color: $white;
                    background-color: $gray-800;
                }
            }
        }

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 80px;
            padding-right: 15px;

            svg {
                fill: currentColor;
            }
        }

        &-description {
            margin-top: 5px;
            font-size: $font-size-sm;
            font-weight: normal;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        padding: 0 $g;
        height: 80px;
    }

    &__social {
        list-style: none;
        display: flex;
        align-items: center;
        line-height: 0;
        margin: 0 -10px;
        padding: 0;

        &-link {
            padding: 5px 10px;
            display: block;

            &:hover {
                color: $red;
                text-decoration: none;
            }
        }
    }

    // &__language {
    //     display: flex;
    //     align-items: center;
    //     @include list;
    //     font-size: 1.4rem;
    //     font-weight: 700;

    //     &-item {
    //         &:not(:last-child) {
    //             margin-right: $g-sm;
    //         }
    //     }

    //     &-link {
    //         display: block;
    //         color: $gray-900;
    //         text-transform: uppercase;
    //         opacity: .5;

    //         &:hover {
    //             text-decoration: none;
    //             opacity: .6;
    //         }

    //         .is-active & {
    //             color: $body-color;
    //             opacity: 1;
    //         }
    //     }
    // }
}

.c-ideil {
    &__link {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;

        &:hover {
            color: $red;
            text-decoration: none;
        }
    }

    &__logo {
        margin-left: 2px;
        margin-bottom: 3px;
    }
}

@include media-breakpoint-down(lg) {

    .c-banner {
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        &.c-banner--delivery {
            top: 30px;
            height: 60px;
        }

        &__inner {
            position: fixed;
            left: 0;
            bottom: 0;
            width: $sidebar;
            max-width: 100%;
            background-color: $white;
            transform: translateX(-100%);
            transition: $transition;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            .is-sidebar & {
                transform: translateX(0);
            }
        }

        &__logo {
            .c-banner--delivery & {
                width: 100px;
            }
        }
    }

}

@include media-breakpoint-down(sm) {

    .c-banner {
        &.c-banner--delivery {
            top: 22px;
            height: 38px;
        }

        &__logo {
            .c-banner--delivery & {
                width: 60px;
            }
        }
    }

}

@include media-breakpoint-up(md) {

    .c-banner {
        height: 90px;

        &__inner {
            top: 90px;
        }

        &__burger {
            left: $o-lg;
        }

        &__logo {
            width: 120px;
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-banner {
        flex-shrink: 0;
        flex-direction: column;
        position: sticky;
        top: 0;
        height: auto;
        min-height: 100vh;
        width: $sidebar;

        &__burger {
            display: none;
        }

        &__logo {
            width: auto;
            flex-shrink: 0;
            margin: $sidebar-spacer auto $sidebar-spacer - 10;
        }

        &__nav {
            padding-top: 0;
        }
    }

}

@media (min-width: breakpoint(hd)) and (min-height: 920px) {

    .c-banner {
        &__logo {
            margin-top: $sidebar-spacer-lg;
            margin-bottom: $sidebar-spacer-lg - 10;
        }

        &__nav {
            margin-bottom: $sidebar-spacer-lg;
        }

        &__partners {
            margin-bottom: $sidebar-spacer-lg;
        }
    }

}
