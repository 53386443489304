.c-locations {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 480px;
    max-width: 100%;
    padding: $o-lg $g 0;
    background-color: $gray-800;
    transition: $transition;
    transform: translateX(-100%);
    z-index: 9;

    .is-locations & {
        transform: translateX(0);
    }

    &__list {
        &:after {
            content: '';
            display: block;
            height: $g;
        }

        &-item {
            margin-top: $g;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    
    .c-locations {
        z-index: 10;
    }

}

@include media-breakpoint-up(md) {

    .c-locations {
        top: 90px;
    }

}

@include media-breakpoint-only(lg) {

    .c-locations {
        .is-locations & {
            transform: translateX($sidebar);
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-locations {
        top: 0;
        left: $sidebar;
    }

}