.c-dishes {
    margin-top: $o-lg;

    &__title {
        margin-bottom: $g;
        text-align: center;
        font-size: $h4-font-size;
        font-weight: 800;
    }

    &__list {
        @include list;

        &-item {
            margin-bottom: $g;
        }
    }

    .btn-primary {
        width: 100%;
    }
}

.c-dish {
    &__title {
        font-size: $h5-font-size;
        margin: $o - 5 0;
    }

    &__price {
        margin-top: $g-sm;
        font-weight: 700;
        font-size: $font-size-sm;
    }
}

@include media-breakpoint-up(md) {

    .c-dishes {
        margin-top: $g-lg;

        &__title {
            margin-bottom: $o-lg;
            font-size: $h2-font-size - 1;
        }

        &__list {
            &-item {
                margin-bottom: $o-lg;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-dish {
        display: flex;

        &__content {
            flex-shrink: 0;
            width: $column;
            margin-left: $o-lg;
        }

        &__img {
            flex-grow: 1;
        }

        &__title {
            margin: $o 0;
        }

        .c-dishes__list-item:nth-child(odd) & {
            flex-direction: row-reverse;

            &__content {
                margin-left: 0;
                margin-right: $o-lg;
            }
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-dish {
        &__content {
            margin-left: $gutter;
        }

        &__title {
            margin: $g 0;
        }

        .c-dishes__list-item:nth-child(odd) & {
            &__content {
                margin-right: $gutter;
            }
        }
    }

    .c-dishes {
        margin-top: 180px;

        &__title {
            margin-bottom: 100px;
            font-size: $h2-font-size;
        }

        &__list {
            &-item {
                margin-bottom: $g-lg;
            }
        }
    }

}