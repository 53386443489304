.c-home {
    display: flex;
    align-items: center;
    position: relative;
    min-height: calc(100vh - 60px);
    padding: $o-lg 0;
    overflow: hidden;

    &__inner {
        width: 100%;
    }

    &__main {
        max-width: 450px;

        > .btn-primary {
            margin-top: $o;
        }
    }

    &__grid {
        display: flex;
        flex-direction: column;
        margin-bottom: -$g;

        &-item {
            margin-bottom: $g;
        }
    }

    &__title {
        margin-bottom: $o;
        font-size: $h4-font-size;

        &--lg {
            font-size: $h3-font-size;
        }
    }

    &__bg-1 {
        display: none;
    }

    &__bg-2,
    &__bg-3 {
        position: absolute;
        z-index: -1;
        pointer-events: none;
    }

    &__bg-2 {
        top: 40px;
        left: calc(100% - 110px);
        width: 100px;
    }

    &__bg-3 {
        bottom: 0;
        left: $g-sm;
        width: 150px;
    }

    &__bg-4 {
        display: none;
    }

    &--1 {
        background: url(../img/home-1.jpg) no-repeat bottom right;
        background-size: cover;
    }

    &-online {
        margin-top: -85px;

        @include media-breakpoint-down(md) {
            margin-top: 0;
            display: flex;
            align-items: center;
            position: relative;
            min-height: calc(100vh - 60px);
            padding-top: $o-lg;
        }

        &__wrap {
            width: 100%;
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
        }

        &__title {
            font-size: $h4-font-size;
            flex-shrink: 0;
            margin: 0;
            margin-bottom: 30px;
            width: 100%;
            max-width: 560px;

            @include media-breakpoint-up(md) {
                font-size: $h3-font-size;
                margin-bottom: 40px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 100%;
                width: 35%;
                font-size: $font-size-lg;
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                font-size: $h4-font-size;
            }
        }

        &__media {
            min-width: 1px;
            position: relative;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
            background-color: $body-bg;
            text-align: center;
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 65%;
            }

            .swiper-container {
                width: 100%;
                padding: 10px;
                padding-bottom: 0;

                @include media-breakpoint-up(lg) {
                    padding: 20px;
                    padding-bottom: 0;
                }

                @include media-breakpoint-down(md) {
                    overflow: visible;
                }
            }

            .swiper-wrapper {
                display: flex;

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                    margin: 0 -20px;
                    width: auto;
                }
            }

            .swiper-slide {
                width: calc(25% - 15px);
                margin-right: 20px;

                @media (max-width: 1590px) {
                    width: calc(33.33% - 13px);
                }

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                    width: 25%;
                    padding: 0 10px;
                    margin-bottom: 30px;
                }

                @include media-breakpoint-down(sm) {
                    width: 33.333%;
                }

                @include media-breakpoint-down(xs) {
                    width: 50%;
                }
            }

            .swiper-pagination {
                width: 100%;
                line-height: 0;
                left: 0;
                top: calc( 100% + 20px );

                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .swiper-pagination-bullet {

                &:not(:last-child) {
                    margin-right: 5px;
                }
            }

            .swiper-pagination-bullet-active {
                background-color: $danger;
            }
        }

        &__img {
            position: relative;
            margin-bottom: 20px;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }

            &:after {
                content: '';
                width: 100%;
                padding-bottom: 100%;
                display: block;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                filter: grayscale(1);
                transition: $transition;

                @include media-breakpoint-down(md) {
                    filter: none;
                }
            }

            &-play {
                width: 66px;
                height: 66px;
                border-radius: 50%;
                background-color: $white;
                position: absolute;
                left: calc(50% - 33px);
                top: calc(50% - 33px);
                transition: $transition;

                @include media-breakpoint-down(md) {
                    color: $danger;
                }

                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9.5px 0 9.5px 16px;
                    border-color: transparent transparent transparent currentColor;
                    position: absolute;
                    left: 27px;
                    top: 23px;
                }
            }
        }

        &__text {
            font-size: 16px;
            line-height: 22px;
            display: block;
            font-weight: 700;
            opacity: .5;
            transition: opacity $transition;

            @include media-breakpoint-up(lg) {
                font-size: 18px;
            }
        }

        &__link {
            display: block;

            &:hover {
                text-decoration: none;

                .c-home-online {

                    &__img {

                        img {
                            filter: grayscale(0);
                            box-shadow: 0 10px 30px 0 rgba($black, .5);
                        }

                        &-play {
                            color: $danger;
                        }
                    }

                    &__text {
                        opacity: 1;
                    }
                }
            }
        }

        .o-wrapper {
            width: 100%;
        }
    }

    &-space {

        &__pictures {
            display: flex;
            flex-wrap: wrap;
            margin-top: 60px;
            margin-bottom: 40px;

            &-item {
                margin-bottom: 40px;
            }
        }

        &__info {
            max-width: 540px;

            &-btn {
                margin-top: 34px;
            }
        }

        &__bg {
            display: none;
        }

        picture {
            display: block;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

@include media-breakpoint-down(sm) {

    .c-home {
        &__bg-2,
        &__bg-3,
        &__bg-4 {
            transform: none !important;
        }

        &-space {

            &__pictures {

                &-item {

                    picture {
                        transform: none !important;
                    }
                }
            }
        }
    }

}

@include media-breakpoint-up(sm) {
    .c-home {

        &-space {

            &__pictures {
                margin-bottom: 80px;

                &-item {
                    margin: 0;

                    &:nth-child(1) {
                        width: 47%;
                        padding-top: 130px;
                    }

                    &:nth-child(2) {
                        width: calc(53% + 20px);
                        margin-left: -20px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {

    .c-home {
        padding: $g-lg 0;
        min-height: calc(100vh - 90px);

        &__main {
            max-width: 560px;

            > .btn-primary {
                margin-top: 50px;
            }
        }

        &__title {
            margin-bottom: $g;
            font-size: $h3-font-size;

            &--lg {
                font-size: $h2-font-size;
            }
        }

        &__grid {
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -$o;
            margin-right: -$o;

            &-item {
                width: 50%;
                padding: 0 $o;

                &--lg {
                    width: 100%;
                }
            }
        }

        &__bg-2 {
            width: 16%;
            top: 10px;
            left: 33%;
            max-width: 261px;
        }

        &__bg-3 {
            width: 16%;
            left: 60px;
            bottom: 10px;
            max-width: 253px;
        }

        &--2 {
            .c-home {
                &__inner {
                    margin-top: 8%;
                }
            }
        }

        &-space {

            &__pictures {
                margin-top: 0;
                margin-bottom: 120px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .c-home {
        &__bg-1 {
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            max-width: 40%;
            text-align: right;
        }

        &__bg-4 {
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            max-width: 28%;
        }
    }

}

@include media-breakpoint-only(lg) {

    .c-home {
        &__grid {
            &-item {
                width: 33.3334%;

                &--lg {
                    width: 100%;
                }
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-home {
        min-height: 100vh;
    }

}

@include media-breakpoint-up(hd) {

    .c-home {
        &__bg-4 {
            max-width: 35%;
        }

        &-space {

            &__bg {
                display: block;
                position: absolute;
                left: 55%;
                bottom: 130px;
                width: 30%;
            }
        }
    }
}

@include media-breakpoint-up(laptop) {

    .c-home {
        padding: 130px 0;

        &-space {
            padding-top: 0;
        }

        &__grid {
            margin-left: $gutter / -2;
            margin-right: $gutter / -2;

            &-item {
                padding: 0 $gutter / 2;
            }
        }

        &__title {
            font-size: $h2-font-size;

            &--lg {
                font-size: $h1-font-size;
            }
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-home {
        &__grid {
            &-item {
                width: 33.3334%;

                &--lg {
                    width: 100%;
                }
            }
        }
    }

}
