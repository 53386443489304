.c-modal {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transform: translateY($g);
    transition: $transition;
    z-index: 10;

    &.is-open {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }

    &__inner {
        position: relative;
        width: 100%;
        max-width: 480px;
        height: 100%;
        margin: 0 auto;
        padding: $g $g 0;
        background-color: $gray-800;
        pointer-events: auto;
        color: $white;

        &:after {
            content: '';
            display: block;
            height: $g + 10;
        }
    }

    &__close {
        z-index: 2;
        margin-bottom: 50px;
        padding: 8px;
        border: none;
        background: none;
        cursor: pointer;
        color: $red;

        svg {
            display: block;
            fill: currentColor;
        }

        &:hover {
            color: darken($red, 10);
        }
    }

    &__title {
        margin-bottom: $g-sm;
        font-size: $font-size-base;
    }

    &__body {
        margin-bottom: 30px;
    }

    &__success {
        margin: $o 0;
        font-size: $h5-font-size;
        font-weight: 800;
        text-align: center;
        line-height: 1.1;

        p {
            margin-bottom: 2.5rem;
        }
    }

    .btn {
        width: 100%;
    }
}

.c-new-rest {
    &__body {
        position: relative;
    }

    &__overlay {
        min-height: 60vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        position: relative;
        z-index: 1;
        padding: $g $g-sm + 5 $g-sm;
        color: $white;
        background-color: rgba($black, .2);
    }

    &__title {
        margin-bottom: 3px;
        font-size: $h6-font-size;
        line-height: $line-height-base;

        img {
            vertical-align: top;
            width: 1em;
            height: 1em;
        }
    }

    &__address {
        font-size: 1.4rem;
        font-weight: 700;

        a {
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__bg {

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .btn {
        margin-top: $g-sm;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 8px;
        background: none;
        border: none;
        z-index: 2;
        color: $white;
        cursor: pointer;

        svg {
            display: block;
            fill: currentColor;
            pointer-events: none;
        }

        &:hover {
            color: $danger;
        }
    }

    .modal-content {
        border: none;
    }
}

.c-quarantine {
    &__body {
        position: relative;
    }

    &__overlay {
        position: relative;
        z-index: 1;
        padding: $g $g-sm + 5;
        color: $white;
    }

    &__title {
        font-size: $h6-font-size;
        margin-bottom: 24px;
        line-height: $line-height-base;
    }

    &__text {
        font-size: 1.6rem;

        a {
            color: currentColor;

            &:hover {
                text-decoration: underline;
            }
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &.t-big {
            font-size: 2rem;
        }
    }

    &__bg {

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba($black, .7);
        }
    }

    .btn {
        margin-top: $g-sm;
    }

    &__close {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 8px;
        background: none;
        border: none;
        z-index: 2;
        color: $white;
        cursor: pointer;

        svg {
            display: block;
            fill: currentColor;
            pointer-events: none;
        }

        &:hover {
            color: $danger;
        }
    }

    .modal-content {
        border: none;
        overflow: hidden;
    }
}

.c-item-modal {
    &__body {
        padding: 0 $g-sm;
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &:before,
        &:after {
            content: '';
            display: block;
        }

        &:before {
            height: $g-sm;
        }

        &:after {
            height: 90px;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px $g-sm;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
        box-shadow: 0px 0px 1px rgba($black, .04), 0px 0px 2px rgba($black, .06), 0px -4px 8px rgba($black, .04);
    }

    &__close {
        position: absolute;
        top: -7px;
        right: -7px;
        padding: 7px;
        border: none;
        background: none;
        cursor: pointer;

        svg {
            display: block;
            fill: currentColor;
            pointer-events: none;
        }

        &:hover {
            color: $red;
        }
    }

    &__preview {
        position: relative;
        margin-bottom: $o-sm;
        
        &-inner {
            position: relative;

            &:before {
                content: '';
                display: block;
                padding-bottom: 100%;
            }
    
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
            }
        }
    }

    &__title {
        margin-bottom: 15px;
        line-height: $headings-line-height;
        font-size: 2.5rem;
        font-weight: 700;
    }

    &__description {
        margin-bottom: $g-sm;
        font-size: 1.6rem;
        opacity: .5;
    }

    &__list {
        padding-left: 0;
        margin-bottom: $g-sm;
        list-style: none;
        font-size: 1.6rem;
        opacity: .5;
        line-height: $headings-line-height;

        &-item {
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            
            &:before {
                content: '';
                position: absolute;
                height: 1px;
                left: 0;
                right: 0;
                bottom: 3px;
                background-image: repeating-linear-gradient(to right, $gray-500, $white 1px, $white 3px);
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        &-title {
            position: relative;

            span {
                background-color: $white;
                padding-right: 10px;
            }
        }

        &-value {
            position: relative;
            margin-left: $o;
            padding-left: 10px;
            flex-shrink: 0;
            background-color: $white;
        }
    }

    &__price {
        font-size: 2.5rem;
        font-weight: 700;
    }

    .modal-content {
        border: none;
        height: 100%;
    }

    .modal-dialog-centered {
        height: 100%;
        max-width: 900px;
        margin: 0 auto;

        &:before {
            height: calc(100vh - (#{$modal-dialog-margin} * 2));
        }
    }
}

.c-order-modal {
    &__body {
        position: relative;
        padding: $g-sm;

        p {
            margin-bottom: 25px;
            font-size: 1.6rem;
        }
    }

    &__close {
        position: absolute;
        top: 13px;
        right: 13px;
        padding: 7px;
        border: none;
        background: none;
        cursor: pointer;

        svg {
            display: block;
            fill: currentColor;
            pointer-events: none;
        }

        &:hover {
            color: $red;
        }
    }

    &__list {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        &-item {
            padding-bottom: $o-sm;
            margin-bottom: $o-sm;
            border-bottom: 1px solid #C4C4C4;
        }
    }

    &__total {
        padding-left: 0;
        margin-bottom: $o;
        list-style: none;

        &-item {
            display: flex;
            align-items: center;
            line-height: $headings-line-height;
            font-size: 1.6rem;

            &--lg {
                font-size: 2rem;
            }

            &:not(:last-child) {
                margin-bottom: 7px;
            }
        }

        &-title {
            margin-right: 5px;
        }
    }

    &__title {
        margin-bottom: 10px;
        font-size: 3.6rem;
        font-weight: normal;
    }

    .modal-content {
        border: none;
    }

    .o-delivery-btn {
        margin-top: $g-sm;
        width: 100%;
    }

    @media (min-width: 480px) {
        &__body {
            padding: $g;
        }

        &__title {
            font-size: 4rem;
        }

        .modal-dialog {
            max-width: 420px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media (max-width: 359px) {

    .c-new-rest {
        &__overlay {
            padding: $g $o-sm $o-sm;
        }

        &__title {
            font-size: 1.8rem;
        }

        .btn {
            margin-top: $o-sm;
        }
    }
}

@include media-breakpoint-up(sm) {

    .c-modal {
        &__inner {
            padding-left: $g-lg;
            padding-right: $g-lg;
        }
    }

    .c-new-rest {

        &__overlay {
            min-height: 320px;
        }
    }

    .c-quarantine {

        &__title {
            font-size: $h4-font-size;
            margin-bottom: 30px;
        }

        &__text {
            font-size: 1.8rem;

            &.t-big {
                font-size: 2.4rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {

    .c-modal {
        top: 90px;

        &__close {
            margin-bottom: 70px;
        }

        &__body {
            margin-bottom: 55px;
        }

        &__success {
            margin: $o-lg 0;
        }
    }

    .c-new-rest {

        &__title {
            font-size: 2.6rem;
        }

        &__address {
            font-size: 1.6rem;
        }
    }

    .c-item-modal {
        &__body {
            padding: 0;
            position: relative;
            overflow-y: inherit;

            &:before,
            &:after {
                display: none;
            }
        }

        &__preview {
            position: relative;
            padding: $g $g-sm;
            margin-bottom: 0;
            margin-left: 45%;
            width: 55%;
        }

        &__close {
            top: 13px;
            right: 13px;
        }

        &__main {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 1px;
            width: 45%;
            overflow: hidden;
        }

        &__info {
            position: relative;
            padding: 0 $g-sm;
            max-height: 100%;
            overflow: hidden;

            &:before,
            &:after {
                content: '';
                display: block;
            }

            &:before {
                height: $g;
            }

            &:after {
                height: 90px;
            }

            .ps__rail-y {
                right: 3px !important;
                background-color: rgba(#C4C4C4, .3);
                opacity: 1;
            }

            .ps__thumb-y {
                background-color: #C4C4C4;
            }
        }

        &__action {
            bottom: 1px;
            right: calc(55% + 6px);
            padding-right: $g-sm - 6px;
            padding-top: $o-sm;
            padding-bottom: $o-sm;
            box-shadow: none;
        }

        .modal-dialog-centered {
            min-height: 550px;
            height: calc(100% - (#{$modal-dialog-margin} * 2));
            width: calc(100% - (#{$modal-dialog-margin} * 2));
            margin-top: $modal-dialog-margin;
            margin-bottom: $modal-dialog-margin;
        }

        .modal-content {
            min-height: 0;
            max-height: 100%;
            height: auto;
        }
    }
}

@include media-breakpoint-up(lg) {

    .c-modal {
        top: 90px;

        &__close {
            margin-bottom: 70px;
        }

        &__body {
            margin-bottom: 55px;
        }

        &__success {
            margin: $o-lg 0;
        }
    }

    .c-new-rest {
        &__overlay {
            padding: 35px 50px;
            min-height: 600px;
        }

        &__title {
            margin-bottom: 5px;
            font-size: 4.8rem;
        }

        &__address {
            font-size: 1.8rem;
        }

        &__close {
            top: 15px;
            right: 15px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .btn {
            margin-top: $o;
        }
    }

    .c-quarantine {
        &__overlay {
            padding: 50px;
            max-width: 730px;
        }

        &__title {
            margin-bottom: 30px;
            font-size: 4.8rem;
        }

        &__text {
            font-size: 2rem;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &.t-big {
                font-size: 3rem;
            }
        }

        &__close {
            top: 15px;
            right: 15px;

            svg {
                width: 24px;
                height: 24px;
            }
        }

        .btn {
            margin-top: $o;
        }
    }

    .c-item-modal {
        &__preview {
            padding-left: $o;
            padding-right: $o;
        }

        &__info {
            padding-left: $g;
            padding-right: $g;
        }

        &__action {
            padding: $g-sm ($g - 6px) $g-sm $g;
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-modal {
        top: 0;
        left: $sidebar;

        &__inner {
            margin-left: 0;
        }

        &__success {
            margin: 150px 0;
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-item-modal {
        &__info {
            &:after {
                height: 105px;
            }
        }
    }

}
