$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    hd: 1340px,
    laptop: 1420px,
    desktop: 1660px,
    fullhd: 1800px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    hd: 1320px,
    laptop: 1380px,
    desktop: 1620px,
    fullhd: 1740px
);

$layout-min-width:                                  320px;
$layout-max-width:                                  1920px;
$grid-gutter-width:                                 40px;

$g:                                                 $grid-gutter-width;
$g-xs:                                              $g / 8;
$g-sm:                                              $g / 2;
$g-md:                                              $g-sm * 3;
$g-lg:                                              $g * 2;

$o:                                                 30px;
$o-xs:                                              $o / 3;
$o-sm:                                              $o / 2;
$o-md:                                              $o-sm * 3;
$o-lg:                                              $o * 2;

$spacers: (
    0: 0,
    1: $g-xs,
    2: $o-xs,
    3: $o-sm,
    4: $g-sm,
    5: $o,
    6: $g
);

$transition:                                        .3s;

$sidebar:                                           320px;
$wrapper:                                           1200px;
$gutter:                                            120px;
$sidebar-spacer:                                    30px;
$sidebar-spacer-lg:                                 50px;
$column:                                            320px;

$delivery: #D9E021;

$delivery-banner-bg: #B83926;
$delivery-banner-color: $white;
