.nav-tabs {
    border-bottom: 0;
    margin-bottom: $g-sm;
    // margin-bottom: $o;

    .nav-item {
        margin-bottom: $o-xs;

        &:not(:last-child) {
            margin-right: $g;
        }
    }

    .nav-link {
        padding: 0;
        border: none;
        font-weight: 800;
        font-size: $font-size-sm;
        // font-size: 1.8rem;
        border-bottom: 2px solid transparent;
        opacity: .5;

        &:hover, &:focus {
            border-bottom-color: transparent;
            opacity: 1;
        }

        &.active {
            border-bottom-color: $red;
            opacity: 1;
            cursor: default;
        }
    }
}

.form-group {
    position: relative;

    &--last {
        margin-bottom: 0;
    }
}

.form-control {
    font-size: $font-size-sm;
    font-weight: 700;
    box-shadow: none !important;

    &:focus, &.is-filled {
        ~ .form-label {
            font-size: 1rem;
            color: $red;
            text-transform: uppercase;
            background-color: $gray-800;
            opacity: 1;
            transform: translateY(0) !important;
        }
    }

    &--dark {
        color: $black;
        border-color: rgba($black, .5);

        &::placeholder {
            color: $black;
            opacity: .5;
        }

        &:focus, &.is-filled {
            color: $black;
            border-color: $black;

            ~ .form-label {
                color: $black;
                background-color: $body-bg;
            }
        }

        &[readonly] {
            color: rgba($black, .5);
            background: none;
            border-color: rgba($black, .5);
            cursor: default;
        }
    }
}

.form-label {
    padding: 0 10px;
    position: absolute;
    top: -6px;
    left: 15px;
    margin-bottom: 0;
    font-weight: 700;
    font-size: $font-size-sm;
    color: $white;
    text-transform: none;
    background-color: transparent;
    opacity: .5;
    transition: $transition;
    transform: translateY(20px);
    pointer-events: none;
    will-change: transform;

    &--dark {
        color: $black;
    }
}

.invalid-feedback {
    margin-top: 5px;
    font-weight: 700;
    font-size: 1.2rem;
}

@media (max-width: 359px) {

    .modal-dialog {
        margin-left: 5px;
        margin-right: 5px;
    }

}

@include media-breakpoint-up(sm) {

    .nav-tabs {
        .nav-link {
            font-size: 2rem;
        }
    }

}

@include media-breakpoint-up(md) {

    .nav-tabs {
        margin-bottom: $o;

        .nav-link {
            font-size: 2.5rem;
        }
    }

    .form-control {
        height: 60px;
    }

    .form-label {
        transform: translateY(25px);
    }

}