*:focus {
    outline: none !important;
}

html {
    font-size: 10px;
}

body {
    background-color: $body-bg;
    fill: $body-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    max-width: 100%;
    height: auto;
}

button,
.btn,
a {
    transition:
        color $transition,
        background $transition,
        border-color $transition,
        fill $transition,
        opacity $transition;
}

textarea {
    resize: vertical;
}

abbr[title] {
    text-decoration: none;
    border-bottom: 0;
    cursor: inherit !important;
}

.c-link {
    display: inline-block;
    position: relative;
    padding-bottom: 2px;
    font-size: 1.4rem;
    font-weight: 700;
    overflow: hidden;
    text-decoration: none !important;

    &:after {
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: currentColor;
        transition: $transition;
    }

    &:hover {
        &:after {
            transform: translateX(-105%);
        }
    }
}

.c-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    z-index: 8;

    .is-locations &,
    .is-modal & {
        opacity: .5;
        visibility: visible;
    }
}

.c-distance,
.c-schedule {
    position: relative;
    padding-left: 24px;
    flex-shrink: 0;
    font-size: $font-size-sm;
    color: $body-color;

    &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 2px;
        background: url(../img/svg/location.svg) no-repeat;
    }
}

.c-distance {
    font-weight: 700;
}

.c-schedule {
    &__item {
        display: inline-block;
    }
    
    &:after {
        background: url(../img/svg/oclock.svg) no-repeat;
    }

    span {
        font-weight: 700;
    }
}

.c-bullets {
    @include list;

    &__item {
        position: relative;
        padding-left: $g-sm;

        &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            position: absolute;
            top: 8px;
            left: 0;
            border-radius: 50%;
            background-color: currentColor;
        }

        &:not(:last-child) {
            margin-bottom: $o-sm;
        }

        // &:first-child:last-child {
        //     padding-left: 0;

        //     &:before {
        //         display: none;
        //     }
        // }
    }
}

.c-list {
    @include list;
    margin-bottom: -$o-sm;
    font-size: $font-size-sm;

    &__item {
        margin-bottom: $o-sm;
        color: $gray-700;

        &--divider {
            margin-bottom: $o-sm + 10;
        }
    }

    a {
        text-decoration: underline;
        color: $body-color;

        &:hover {
            text-decoration: none;
        }
    }

    &--inline {
        display: flex;
        flex-wrap: wrap;

        .c-list__item {
            margin-right: $o;
        }
    }

    & + & {
        margin-top: $o;
    }
}

.c-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    pointer-events: none;

    span {
        width: 26px;
        height: 26px;
        margin: 0 20px;
        background: url(../img/svg/preloader.svg) no-repeat;
        background-size: contain;
    }

    .is-loading & {
        opacity: 1;
        visibility: visible;

        span {
            animation: loader 1.5s ease-in-out infinite;

            &:nth-child(2) {
                animation-delay: .3s;
            }

            &:nth-child(3) {
                animation-delay: .6s;
            }
        }
    }
}

@keyframes loader {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .1;
    }
    100% {
        opacity: 1;
    }
}

@include media-breakpoint-down(lg) {

    .is-sidebar, .is-modal {
        &, body {
            overflow: hidden;
        }
    }

    .c-backdrop {
        .is-sidebar & {
            opacity: .5;
            visibility: visible;
        }
    }

}

@include media-breakpoint-down(sm) {

    .is-cart {
        &, body {
            overflow: hidden;
        }
    }

    .c-backdrop {
        .is-cart & {
            opacity: .5;
            visibility: visible;
        }
    }

}

@include media-breakpoint-up(md) {

    .c-bullets {
        &__item {
            &:not(:last-child) {
                margin-bottom: $g-sm;
            }
        }
    }

    .c-list {
        &--2,
        &--3 {
            columns: 2;
            column-gap: $o-lg;

            .c-list__item {
                -webkit-column-break-inside: avoid;
                page-break-inside: avoid;
                break-inside: avoid;
            }
        }
        & + & {
            margin-top: $g;
        }
    }
    
}

@include media-breakpoint-up(laptop) {

    .c-list {
        &--2, &--3 {
            column-gap: $gutter;
        }

        & + & {
            margin-top: $o-lg;
        }
    }
    
}

@include media-breakpoint-up(desktop) {

    .c-list {
        &--3 {
            columns: 3;
        }
    }
    
}