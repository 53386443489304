.c-restaurant {
    &__img {
        margin-bottom: $o-sm;

        img {
            width: 100%;
        }
    }

    &__schedule {
        margin-top: $o-xs;
    }
    
    &__title {
        font-size: $h5-font-size;
        margin-top: $o-sm;
        margin-bottom: 0;
        font-weight: 800;
        line-height: 1;
    }

    &__address {
        margin-top: $o-xs;
        font-size: $font-size-sm;
        opacity: .5;
        line-height: $font-size-base;
    }

    &__description {
        margin-top: $o-sm;
        font-size: $font-size-sm;

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        margin-top: $g-sm;
    }

    & + & {
        margin-top: $g;
    }
}

@include media-breakpoint-down(md) {
    
    .c-restaurant {
        &__content {
            transform: none !important;
        }
    }

}

@include media-breakpoint-up(sm) {

    .c-restaurant {
        &__info {
            display: flex;
            align-items: flex-start;
        }

        &__distance {
            flex-shrink: 0;
        }
        
        &__schedule {
            flex: 1;
            margin-top: 0;
            
            .c-restaurant__distance + & {
                margin-left: $o - 5;
            }
        }
    }

}

@include media-breakpoint-up(md) {
    
    .c-restaurant {
        &__img {
            margin-bottom: $o;
        }

        &__title {
            margin-top: $o;
            font-size: $h4-font-size;
        }

        &__description {
            margin-top: $g-sm;
        }

        & + & {
            margin-top: $o-lg;
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-restaurant {
        padding-top: $o-lg;
        padding-bottom: $o-lg;
        overflow: hidden;
        pointer-events: none;

        &__inner {
            display: flex;
        }

        &__img {
            position: relative;
            align-self: flex-start;
            flex-grow: 1;
            transition: box-shadow $transition;
            pointer-events: all;
            margin-bottom: 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -$o-lg;
                left: -1000px;
                right: -1000px;
                bottom: $o-lg;
                background-color: $gray-300;
                transition: $transition;
                opacity: 0;
                transform: scaleY(0);
                transform-origin: top left;
                z-index: -1;
            }
        }

        &__content {
            flex-shrink: 0;
            width: $column;
            margin-left: $o-lg;
            opacity: 0;

            .is-offset & {
                transition: transform $transition;
                opacity: 1;
            }
        }

        &__main {
            opacity: 0;
            transition: $transition;
            pointer-events: none;
        }

        &__heading {
            pointer-events: all;
        }

        &__link {
            margin-top: $g;
        }

        .no-touchevents &:hover, .touchevents & {
            pointer-events: all;

            .c-restaurant {
                &__img {
                    box-shadow: 0 20px 40px rgba($black, .3);
                    transition-delay: .15s;

                    &:before {
                        opacity: 1;
                        transform: scaleY(1);
                    }
                }

                &__content {
                    transform: translateY(0) !important;
                }

                &__main {
                    opacity: 1;
                    pointer-events: all;
                    transition-delay: $transition;
                }
            }
        }

        & + & {
            margin-top: 0;
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-restaurant {
        &__content {
            margin-left: $gutter;
        }

        &__link {
            margin-top: 70px;
        }
    }

}