.c-catering {

  &-title {
    max-width: 880px;
    margin-bottom: 60px;
  }

  &-media {
    margin-top: $o-lg;
    margin-bottom: $g-lg;

    @include media-breakpoint-up(md) {
      margin-top: $g-lg;
      margin-bottom: 120px;
    }

    @include media-breakpoint-up(laptop) {
      margin-top: 120px;
      margin-bottom: 180px;
    }

    &__row {

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        margin-bottom: $g-lg;
      }
    }

    &__slider {
      position: relative;

      @include media-breakpoint-up(lg) {
        min-width: 1px;
        margin: 0;
        flex-grow: 1;
      }

      .swiper-pagination {
          display: flex;
          align-items: center;
          justify-content: center;
          top: 100%;
          margin-top: $g-sm;
          bottom: auto;
          font-size: $h5-font-size;
          color: $gray-500;

          @include media-breakpoint-up(md) {
            font-size: $h3-font-size;
          }

          @include media-breakpoint-up(laptop) {
            margin-top: $g;
          }
      }

      .swiper-pagination-current,
      .swiper-pagination-total {
          padding: 0 8px;
          font-size: $font-size-sm;
          font-weight: 700;

          @include media-breakpoint-up(md) {
            font-size: $font-size-base;
          }
      }

      .swiper-pagination-current {
          color: $body-color;
      }

      .swiper-button-next,
      .swiper-button-prev {
          display: none;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 100px;
          margin-top: -50px;
          background: $body-color;
          z-index: 1;

          @include media-breakpoint-up(md) {
            display: flex;
          }

          svg {
              fill: $body-bg;
              transition: $transition;
          }

          &.swiper-button-disabled {
              opacity: 1;

              svg {
                  opacity: .2;
              }
          }

          &:hover {
              svg {
                  transform: translateX(3px);
              }
          }
      }

      .swiper-button-prev {

        @include media-breakpoint-up(md) {
          left: -10px;
          transform: rotate(180deg);
        }
      }

      .swiper-button-next {

        @include media-breakpoint-up(md) {
          right: -10px;
        }
      }

      .swiper-slide {
          img {
              width: 100%;
          }
      }
    }

    &__info {
      margin-top: 20px;

      @include media-breakpoint-up(md) {
          margin-top: $o;
      }

      @include media-breakpoint-up(lg) {
        flex-shrink: 0;
        width: $column;
        margin-top: 0;
        margin-left: $g-lg;
      }

      @include media-breakpoint-up(laptop) {
        margin-left: $o-lg * 2;
      }
    }

    &__title {

      @include media-breakpoint-up(lg) {
        margin-top: 0 !important;
      }
    }
  } 

  &-feedback {
    text-align: center;
    max-width: 770px;
    margin: 0 auto;

    &__title {
      font-size: $h4-font-size;
      font-weight: 800;
      margin-bottom: $g;

      @include media-breakpoint-up(md) {
        margin-bottom: $o-lg;
        font-size: $h2-font-size - 1;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: $o;
      }

      @include media-breakpoint-up(laptop) {
        margin-bottom: $o * 2;
      }
    }

    &__text {
      margin-bottom: 20px;

      @include media-breakpoint-up(laptop) {
        margin-bottom: 40px;
      }
    }

    &__phone {
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        font-size: 20px;
      }

      @include media-breakpoint-up(laptop) {
        margin-bottom: 60px;
        font-size: 25px;
      }

      a {
        border-bottom: 1px solid currentColor;
        transition: $transition;

        &:hover {
          border-width: 3px;
          text-decoration: none;
        }
      }
    }
  }
}