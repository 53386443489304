body {
    padding-top: 60px;
}

.o-wrapper {
    max-width: $wrapper + $gutter * 2;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $g-sm;
}

.o-page {
    margin-bottom: $g;
}

@include media-breakpoint-up(md) {

    body {
        padding-top: 90px;
    }

    .o-wrapper {
        padding: 0 $gutter / 2;
    }

    .o-page {
        margin-bottom: $g-lg;
    }

}

@include media-breakpoint-up(xl) {

    body {
        display: flex;
        align-items: flex-start;
        padding-top: 0;
    }

    main {
        width: calc(100% - #{$sidebar});
    }

}

@include media-breakpoint-up(laptop) {

    .o-wrapper {
        padding: 0 $gutter;
    }

    .o-page {
        margin-bottom: $g-lg * 2;
    }

}