.c-checkout {
    margin-top: $g-sm;
    margin-bottom: $g-md;

    &__container {
        max-width: 850px;

        &-sm {
            max-width: 700px;
        }

        &-lg {
            max-width: 950px;
        }
    }

    &__back {
        display: inline-block;
        margin-bottom: 7px;
        font-size: 1.4rem;

        img {
            margin-right: 5px;
            transform: rotate(180deg) translateY(2px);
            transition: transform $transition;
        }

        &:hover {
            text-decoration: none;

            img {
                transform: rotate(180deg) translateX(5px) translateY(2px);
            }
        }
    }

    &__title {
        margin-bottom: $o;
        font-size: 3.6rem;
        font-weight: 800;
        line-height: 1.3;
        text-transform: uppercase;
    }

    &__list {
        position: relative;

        &-item {
            padding-bottom: $o-sm;
            margin-bottom: $o-sm;
            border-bottom: 1px solid #C4C4C4;
            transition: opacity $transition;

            .is-loading & {
                opacity: .3;
                pointer-events: none;
                user-select: none;
            }
        }
    }

    &__total {
        margin-bottom: $o;
        
        &-list {
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }

        &-item {
            display: flex;
            align-items: center;
            line-height: $headings-line-height;
            font-size: 1.6rem;

            &--lg {
                font-size: 2rem;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        &-title {
            margin-right: 5px;
        }
    }

    &__group {
        margin-bottom: $o;

        &-title {
            margin-bottom: $g-sm;
            line-height: $headings-line-height;
            font-size: 2.2rem;
            font-weight: 700;
        }

        .row {
            margin-left: -10px;
            margin-right: -10px;
        }

        [class*="col-"] {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__agree {
        margin-bottom: $form-group-margin-bottom;
        font-size: 1.8rem;
    }

    &__map {
        position: relative;
        max-height: calc(100vh - 60px);
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            padding-bottom: 100%;
            background-color: $gray-100;
        }
    }

    &__empty {
        margin-bottom: $o;

        &-title {
            margin: $o 0 $g-sm;
            font-size: 3rem;
            font-weight: 700;
        }

        &-description {
            max-width: 450px;
            font-size: 1.6rem;
        }
    }

    #checkout-map,
    .vue-map-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.gmap-info-window {
    padding: 10px;
}

@include media-breakpoint-up(md) {

    .c-checkout {
        margin-bottom: $g-lg;

        &__back {
            margin-bottom: $o-sm;
        }

        &__title {
            margin-bottom: 45px;
            font-size: 4.6rem;
        }

        &__total {
            margin-bottom: $o-lg;
            text-align: right;

            &-list {
                display: inline-block;
                text-align: left;
            }

            &-item {
                &--lg {
                    font-size: 2.2rem;
                }
            }
        }

        &__group {
            margin-bottom: 50px;

            &-title {
                margin-bottom: $o;
                font-size: 2.4rem;
            }

            p {
                margin-bottom: $form-group-margin-bottom;
                font-size: 2.4rem;
            }
        }

        &__map {
            &:before {
                padding-bottom: 50%;
            }
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-checkout {
        margin-bottom: $g-lg * 2;

        &__title {
            margin-bottom: 60px;
            font-size: 6.4rem;
        }

        &__list {
            &-item {
                padding-bottom: $g-sm;
                margin-bottom: $g-sm;
            }
        }

        &__total {
            &-item {
                &--lg {
                    font-size: 2.4rem;
                }
            }
        }

        &__empty {
            &-title {
                margin-top: $g;
                font-size: 4.8rem;
            }
        }
    }

}