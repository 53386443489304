.c-page-error {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 60px);

    &__inner {
        max-width: 690px;
        margin: $g 0 $g-lg;
    }

    &__code {
        margin-bottom: $o;
        font-size: $h1-font-size;
        font-weight: 800;
        line-height: 1;
    }

    &__title {
        margin-bottom: $g-sm;
        font-size: 3.4rem;
        font-weight: 800;
    }

    &__btn {
        margin-top: $g-sm;
    }
}

@include media-breakpoint-up(md) {

    .c-page-error {
        min-height: calc(100vh - 90px);

        &__code {
            margin-bottom: $g-lg;
            font-size: $display1-size;
        }

        &__title {
            margin-bottom: $o;
            font-size: $h3-font-size;
        }

        &__btn {
            margin-top: $g;
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-page-error {
        min-height: 100vh;

        &__inner {
            margin-bottom: 150px;
        }
    }

}