input[type=number] {
    -moz-appearance:textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.c-file {
    padding-top: 25px;
    border-top: 1px solid rgba($white, .1);

    &__input {
        display: none;
    }

    &__label {
        display: inline-flex;
        align-items: flex-start;
        margin-bottom: 0;
        cursor: pointer;

        svg {
            flex-shrink: 0;
            margin-top: 2px;
        }

        &:hover {
            .c-file__title {
                text-decoration: none;
            }
        }
    }

    &__title {
        margin-left: 10px;
        font-size: $font-size-sm;
        font-weight: 700;
        text-decoration: underline;
    }
}

.c-number {
    display: flex;
    align-items: center;
    position: relative;
    width: 80px;

    &__btn {
        flex-shrink: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 20px;
        width: 20px;
        padding: 0;
        border: none;
        background-color: $delivery;
        cursor: pointer;
        transition: background-color $transition;

        &:hover {
            background-color: $black;
        }

        &:disabled,
        &.disabled {
            background-color: #D5E1E4;
            cursor: not-allowed;
        }

        &:before, &:after {
            content: '';
            display: block;
            height: 2px;
            width: 10px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -1px 0 0 -5px;
            background-color: $white;
        }

        &:first-of-type {
            &:after {
                display: none;
            }
        }

        &:last-of-type {
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    .form-control {
        height: 20px;
        width: 40px;
        padding: 0 2px;
        font-size: 1.4rem;
        text-align: center;
        font-weight: 700;
        color: $black;
        border: none
    }

    &--lg {
        width: 106px;

        .c-number__btn {
            height: 28px;
            width: 28px;
        }

        .form-control {
            width: 50px;
            height: 28px;
            font-size: 2rem;
        }
    }
}

.c-radio-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.c-checkbox, .c-radio {
    display: inline-block;
    margin-right: $g;
    margin-bottom: 10px;

    input {
        display: none;

        + label {
            position: relative;
            padding-left: 35px;
            margin-bottom: 0;
            line-height: $headings-line-height;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                width: 20px;
                height: 20px;
                position: absolute;
                top: -1px;
                left: 0;
                border: 2px solid $black;
            }

            &:after {
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                top: 4px;
                left: 5px;
                opacity: 0;
                background-color: $black;
                transition: opacity $transition;
            }

            &:hover {
                &:after {
                    opacity: .2;
                }
            }
        }

        &:checked {
            + label {
                &:after {
                    opacity: 1;
                }
            }
        }

        &:disabled {
            + label {
                cursor: not-allowed;
                opacity: .5;

                &:hover {
                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &__description {
        padding-top: 3px;
        padding-left: 35px;
        font-size: 1.4rem;
        max-width: 320px;
    }
}

.c-radio {
    input {
        + label {
            &:before {
                border-radius: 50%;
            }

            &:after {
                border-radius: 50%;
            }
        }
    }
}

@include media-breakpoint-up(md) {

    .c-checkbox, .c-radio {
        input + label {
            font-size: 2.4rem;

            &:before {
                top: 1px;
            }

            &:after {
                top: 6px;
            }
        }
    }

}