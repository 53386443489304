.c-page {
    &__img {
        margin-bottom: $g;

        img {
            width: 100%;
        }
    }

    &__title {
        font-size: $h4-font-size;
        margin-bottom: 0;
        font-weight: 800;
    }

    &__info {
        margin-top: $g-sm;

        &-address {
            a {
                text-decoration: underline;
                font-size: 1.8rem;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &-distance, &-schedule {
            margin-top: $o-xs;
        }
    }

    &__content {
        margin-top: $g;
    }

    &__tabs {
        margin-top: $o;
    }

    &__slider {
        position: relative;

        .swiper-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 100%;
            margin-top: $g-sm;
            bottom: auto;
            font-size: $h5-font-size;
            color: $gray-500;
            z-index: 1;
        }

        .swiper-pagination-current,
        .swiper-pagination-total {
            padding: 0 8px;
            font-size: $font-size-sm;
            font-weight: 700;
            width: 42px;
            text-align: left;
        }

        .swiper-pagination-current {
            color: $body-color;
            text-align: right;
        }

        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }

        .swiper-slide {
            img {
                width: 100%;
            }
        }
    }

    &__contacts {
        margin-top: 100px;

        &-title {
            margin-bottom: $o;
            font-size: $h4-font-size;
            font-weight: 800;
        }

        &-content {
            display: flex;
            flex-direction: column;
        }

        &-label {
            margin-bottom: $g-sm;
            font-weight: 700;
        }

        &-map {
            margin: $o ($g-sm * -1) 0;
            position: relative;
            flex-grow: 1;

            &:before {
                content: '';
                display: block;
                padding-bottom: 68%;
            }
        }
    }

    &__pagination {
        display: none;
    }
}

#route {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@include media-breakpoint-up(sm) {

    .c-page {
        &__info {
            &-inner {
                display: flex;
                align-items: flex-start;
            }

            &-distance {
                flex-shrink: 0;
            }

            &-schedule {
                flex: 1;
                margin-left: $o - 5;
            }
        }
    }

}

@include media-breakpoint-up(md) {

    .c-page {
        &__img {
            margin-bottom: $o-lg;
        }

        &__title {
            font-size: $h2-font-size - 1;
        }

        &__info {
            display: flex;
            align-items: flex-start;
            margin-top: $o;

            &-inner {
                margin-top: 2px;
                margin-left: $o;
            }

            &-address {
                max-width: calc(100% - 320px);
            }

            &-distance,
            &-schedule {
                margin-top: 0;
            }
        }

        &__tabs {
            margin-top: $o-md;
        }

        &__slider {
            .swiper-pagination {
                font-size: $h3-font-size;
            }

            .swiper-pagination-current,
            .swiper-pagination-total {
                font-size: $font-size-base;
            }

            .swiper-button-next,
            .swiper-button-prev {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 100px;
                margin-top: -50px;
                background: $body-color;
                z-index: 1;

                svg {
                    fill: $body-bg;
                    transition: $transition;
                }

                &.swiper-button-disabled {
                    opacity: 1;

                    svg {
                        opacity: .2;
                    }
                }

                &:hover {
                    svg {
                        transform: translateX(3px);
                    }
                }
            }

            .swiper-button-prev {
                left: -10px;
                transform: rotate(180deg);
            }

            .swiper-button-next {
                right: -10px;
            }
        }

        &__contacts {
            margin-top: 120px;

            &-title {
                margin-bottom: $g;
                font-size: $h3-font-size;
            }

            &-map {
                margin: $o-lg ($o-lg * -1) 0;
            }
        }

        &__pagination {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px 0;
            margin-top: $o-lg;
            border-top: 1px solid $gray-400;
            border-bottom: 1px solid $gray-400;

            &-all {
                display: none;
            }

            &-prev,
            &-next {
                width: $column;

                .c-page__pagination-link {
                    display: inline-flex;
                    align-items: center;
                    font-size: $font-size-base;
                    font-weight: 800;

                    svg {
                        flex-shrink: 0;
                        margin-left: $g-sm;
                        margin-bottom: 2px;
                        transition: transform $transition;
                    }

                    &:hover {
                        text-decoration: none;

                        svg {
                            transform: translateX(10px);
                        }
                    }
                }
            }

            &-prev {
                .c-page__pagination-link {
                    svg {
                        order: -1;
                        margin-left: 0;
                        margin-right: $g-sm;
                        transform: rotate(180deg);
                    }

                    &:hover {
                        svg {
                            transform: rotate(180deg) translateX(10px);
                        }
                    }
                }
            }

            &-next {
                text-align: right;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-page {
        &__contacts {
            &-inner {
                display: flex;
                align-items: flex-start;
            }

            &-content {
                flex-shrink: 0;
                width: 240px;
                margin-right: $o-lg;
            }

            &-label {
                margin: $o 0;
            }

            &-map {
                margin: 0;
            }
        }

        &__pagination {
            &-all {
                display: block;
                padding: 0 $o;
                text-align: center;
                line-height: 1;

                .c-page__pagination-link {
                    font-size: $font-size-sm;
                    text-transform: uppercase;
                    font-weight: 700;

                    &:hover {
                        opacity: .5;
                        text-decoration: none;
                    }
                }
            }
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-page {
        &__img {
            margin-bottom: $gutter;
        }

        &__title {
            font-size: $h1-font-size;
        }

        &__info {
            margin-top: $g;
        }

        &__content {
            margin-top: $o-lg;
        }

        &__tabs {
            margin-top: $gutter - $g-sm;
        }

        &__slider {
            .swiper-pagination {
                margin-top: $g;
            }
        }

        &__contacts {
            margin-top: 200px;

            &-title {
                margin-bottom: $g-lg;
                font-size: $h2-font-size;
            }
        }

        &__pagination {
            margin-top: $gutter;

            &-prev,
            &-next {
                .c-page__pagination-link {
                    font-size: $h5-font-size;
                }
            }
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-page {
        &__contacts {
            &-content {
                width: $column;
                margin-right: $gutter;
            }
        }
    }

}