.c-carousel {
    margin-top: $o-lg;

    &__title {
        max-width: 450px;
        margin: 0 auto $g;
        text-align: center;
        font-size: $h5-font-size;
    }

    .swiper-container {
        margin-left: -$g-sm;
        margin-right: -$o;
        padding-left: $g-sm;
    }

    .swiper-slide {
        width: 90%;
        padding-right: $o;
        opacity: .5;
        transition: opacity $transition;
    }
}

@include media-breakpoint-down(md) {

    .c-carousel {
        .swiper-slide {
            &-active {
                opacity: 1;
            }
        }

        .is-end {
            .swiper-slide {
                &-active {
                    opacity: .5;
                }

                &-next {
                    opacity: 1;
                }
            }
        }
    }

}

@include media-breakpoint-up(sm) {

    .c-carousel {
        .swiper-slide {
            width: 70%;
        }
    }

}

@include media-breakpoint-up(md) {

    .c-carousel {
        margin-top: $g-lg;

        &__title {
            max-width: 500px;
            margin-bottom: $o-lg;
            font-size: $h4-font-size;
        }

        .swiper-container {
            margin-left: -$o-lg;
            margin-right: -$o-lg;
            padding-left: $o-lg;
            padding-right: $g-sm;
        }

        .swiper-slide {
            width: 60%;
            padding-right: $g;
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-carousel {
        .swiper-slide {
            width: 45%;
        }
    }

}

@include media-breakpoint-between(lg, laptop) {

    .c-carousel {
        .swiper-slide {
            &-active,
            &-next {
                opacity: 1;
            }
        }

        .is-end {
            .swiper-slide {
                &-active {
                    opacity: .5;
                }

                &-next {
                    + .swiper-slide {
                        opacity: 1;
                    }
                }
            }
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-carousel {
        margin-top: 180px;

        &__title {
            max-width: 630px;
            margin-bottom: $g-lg;
            font-size: $h3-font-size;
        }

        .swiper-container {
            margin-left: -$gutter;
            margin-right: -$gutter;
            padding-left: $gutter;
            padding-right: $gutter - $g;
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-carousel {
        .swiper-container {
            margin-left: calc(#{$sidebar} / 2 + 50% - 50vw);
            margin-right: calc(#{$sidebar} / 2 + 50% - 50vw);
            padding-left: calc((#{$sidebar} / 2 + 50% - 50vw) * -1);
            padding-right: calc((#{$sidebar} / 2 + 50% + #{$g} - 50vw) * -1);
        }

        .swiper-slide {
            width: 32%;

            &-active,
            &-next,
            &-next + .swiper-slide {
                opacity: 1;
            }
        }

        .is-end {
            .swiper-slide {
                &-active {
                    opacity: .5;
                }

                &-next {
                    + .swiper-slide + .swiper-slide {
                        opacity: 1;
                    }
                }
            }
        }
    }

}
