.c-format {
    position: relative;
    max-width: $sidebar;

    &__icon {
        margin-bottom: 25px;
    }

    &__title {
        position: relative;
        margin-bottom: $g-sm;
        font-size: $font-size-lg;
        transition: color $transition;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -50px;
            bottom: -25px;
            left: -$o;
            width: 355px;
            background: url(../img/brash-lg.png) no-repeat center left;
            background-size: contain;
            z-index: -1;
            opacity: 0;
            transform: translateX(-10px) translateY(5px);
            transition: $transition ease-out;
            pointer-events: none;
            will-change: opacity;
        }
    }

    &__action {
        padding-top: $o;
    }

    .no-touchevents & {
        margin-bottom: $o + 50px;

        .c-format__action {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            transition: $transition;
        }

        &:hover {
            .c-format {
                &__title {
                    color: $white;

                    &:before {
                        opacity: 1;
                        transform: translateX(0) translateY(0);
                    }
                }

                &__action {
                    opacity: 1;
                    visibility: visible;
                    transform: translateY(0);
                    transition-delay: .15s;
                }
            }
        }
    }
}

.c-format-lg {
    &__footer {
        &-inner {
            margin-top: $o;
        }

        .btn-primary {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 80px;
            width: 100%;
            margin: $g 0 0 auto;
            padding: $o-xs $g-sm;
            white-space: normal;
            font-size: 1.4rem;
            line-height: 1.4;
            text-align: center;
        }
    }

    &__img {
        margin: $o 0 $o-lg;
        position: relative;

        &:after {
            counter-increment: format;
            content: "0" counter(format);
            display: block;
            position: absolute;
            top: 100%;
            left: $g;
            color: $white;
            font-size: $h1-font-size;
            font-weight: 900;
            line-height: 1;
            transform: translateY(-50%);
            text-shadow: 0 10px 20px rgba($black, .1);
        }

        img {
            width: 100%;
        }
    }

    &__icon {
        margin-bottom: $g-sm;
    }

    &__title {
        margin-bottom: $g-sm;
        font-size: $h5-font-size;
        font-weight: 800;
    }

    &__details {
        @include list;
        font-size: 1.4rem;

        &-item {
            position: relative;
            padding-left: $g;

            &:not(:last-child) {
                margin-bottom: $g-sm;
            }
        }

        &-icon {
            position: absolute;
            top: 0;
            left: 0;
        }

        &-title {
            margin-bottom: 5px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    &__includes {
        &-title {
            margin-bottom: $g-sm;
            text-transform: uppercase;
            font-size: 1.4rem;
            font-weight: 700;
        }

        &-list {
            @include list;
        }

        &-item {
            position: relative;
        }
    }
}

@include media-breakpoint-up(md) {

    .c-format {
        &__icon {
            height: 65px;
        }

        &__title {
            margin-bottom: $o;
            font-size: $h5-font-size;
        }

        .no-touchevents & {
            margin-bottom: $o + 60px;
        }
    }

    .c-format-lg {
        &__footer {
            display: flex;
            align-items: flex-start;

            &-inner {
                margin-top: 0;
                flex-grow: 1;
            }

            .btn-primary {
                max-width: $column;
            }
        }

        &__img {
            margin: $g 0 $g-lg;

            &:after {
                left: $o-lg;
                font-size: $display2-size;
            }
        }

        &__icon {
            margin-bottom: $o;
        }

        &__title {
            margin-bottom: $g;
            font-size: $h4-font-size;
        }

        &__details {
            width: calc(50% - #{$o-lg});
            margin-right: $o-lg;

            &-item {
                &:not(:last-child) {
                    margin-bottom: $o;
                }
            }
        }

        &__includes {
            &-title {
                margin-bottom: $g;
            }
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-format-lg {
        &__main {
            display: flex;
            align-items: flex-start;
            margin-bottom: $g-lg;
        }

        &__content, &__details {
            flex-shrink: 0;
            width: $column;
            margin-right: $o-lg;
        }

        &__img {
            margin: 0;
            flex-grow: 1;
        }
    }

}

@include media-breakpoint-up(laptop) {
    
    .c-format-lg {
        &__main {
            margin-bottom: 120px;
        }

        &__content, &__details {
            margin-right: $gutter;
        }

        &__img {
            &:after {
                font-size: $display1-size;
            }
        }
    }

}

@include media-breakpoint-up(desktop) {
    
    .c-format-lg {
        &__footer {
            &-inner {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
            }

            .btn-primary {
                flex-shrink: 0;
                margin-top: 0;
            }
        }

        &__includes {
            margin-right: $o-lg;
        }
    }
}

@include media-breakpoint-up(fullhd) {
    
    .c-format-lg {
        &__includes {
            margin-right: $gutter;
        }
    }
}
