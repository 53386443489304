.u-fw {
    &-normal {
        font-weight: normal;
    }

    &-semibold {
        font-weight: 500;
    }

    &-bold {
        font-weight: 700;
    }

    &-xbold {
        font-weight: 800;
    }

    &-black {
        font-weight: 900;
    }
}

.u-overflow {
    overflow: hidden;
}

.u-align {
    &-left {
        text-align: left;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }
}

.u-capitalize {
    text-transform: capitalize;
}

@include media-breakpoint-up(md) {

    .u-content {
        width: 500px;

        &--center {
            margin-left: auto;
            margin-right: auto;
        }
    }

}

@include media-breakpoint-up(lg) {

    .u-content {
        width: calc(100% - #{$column});
        // width: calc(100% - #{$column} - #{$o-lg});
    }

}

@include media-breakpoint-up(desktop) {

    .u-content {
        width: calc(100% - #{$column} - #{$gutter});
    }

}