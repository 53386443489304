$height:            50px;
$padding:           $input-padding-x;
$text-size:         1.6rem;
$text-color:        $black;
$bg-color:          $body-bg;
$border-width:      2px;
$border-color:      rgba($black, .5);
$border-radius:     0;
$hover-bg:          #ebebeb;
$active-bg:         $red;
$active-color:      #fff;

.nj-select {
    display: flex;
    position: relative;
  
    &__select {
        width: 100%;
        height: 100%;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
      
        .nj--native & {
            pointer-events: all;
        }
    }
  
    &__list {
        width: 100%;
        max-height: 300px;
        min-width: 200px;
        margin: 5px 0 0;
        padding: 0;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        list-style: none;
        background-color: $bg-color;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        overflow-y: auto;
        opacity: 0;
        visibility: hidden;
      
        &-btn {
            display: block;
            width: 100%;
            padding: 0 $padding;
            color: $text-color;
            font-size: $text-size;
            text-align: left;
            line-height: $height;
            white-space: nowrap;
            text-overflow: ellipsis;
            background: none;
            border: none;
            overflow: hidden;
            cursor: pointer;
            outline: none;

            &:hover {
                background-color: $hover-bg;
            }

            &:disabled {
                cursor: not-allowed;
                background: none;
                opacity: .4;
            }
          
            .nj--active & {
                color: $active-color;
                background-color: $active-bg;
                cursor: default;
            }
        }
         
        .nj--open & {
            opacity: 1;
            visibility: visible;
        }
    }
  
    &__active {
        display: block;
        width: 100%;
        height: $height;
        padding: 0 43px 0 $padding;
        position: relative;
        color: $text-color;
        font-size: $text-size;
        font-weight: 700;
        line-height: $height - $border-width * 2;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        background: $bg-color;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        overflow: hidden;
        cursor: pointer;
        outline: none;

        &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            right: 15px;
            top: 50%;
            bottom: 0;
            margin-top: -4px;
            text-align: center;
            border-top: 8px solid #C4C4C4;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            transition: .3s;
          
            .nj--open & {
                transform: rotate(180deg);
            }
        }

        &.is-placeholder {
            color: rgba($black, .5);
        }
    }
}

@include media-breakpoint-up(md) {

    .nj-select {
        &__active {
            height: 60px;
        }
    }

}