.c-person {
    margin-bottom: $o-lg;

    &__img {
        max-width: $column;
    }

    &__title {
        margin: $g-sm 0;
        font-size: $h5-font-size;
        font-weight: 800;
    }

    &__position {
        margin-bottom: $g-sm;
        font-size: $font-size-sm;
        text-transform: uppercase;
        font-weight: 700;
        opacity: .5;
    }
}

.c-team {
    @include list;
    display: flex;
    flex-direction: column;
    margin-bottom: -$g;

    &__item {
        margin-bottom: $g;
    }
}

.c-card {
    &__img {
        max-width: $column;
        margin-bottom: $g-sm;
    }

    &__title {
        font-size: $h5-font-size;
        margin: 0;
    }

    &__position {
        margin-top: $o-xs;
        font-size: $font-size-sm;
        opacity: .5;
    }
}

.c-job {
    margin-top: $g-lg;

    &__title {
        max-width: 400px;
        margin-bottom: $g;
        font-size: $h4-font-size;
        font-weight: 800;
    }

    &__subtitle {
        font-size: $h5-font-size;
        margin-bottom: $g;
    }

    &__item {
        border-bottom: 1px solid $gray-400;
        
        &:first-child {
            border-top: 1px solid $gray-400;
        }

        &-heading {
            padding: $g-sm 0;
        }

        &-title {
            margin-bottom: 0;
            font-size: $font-size-base;
            font-weight: normal;
        }

        &-link {
            display: block;
            transition: all $transition, font-weight $transition / 3;

            &[aria-expanded="true"] {
                font-weight: 700;
            }

            &:hover {
                text-decoration: none;
                opacity: .5;
            }

            .counter {
                opacity: .5;
            }
        }

        &-description {
            padding-top: $o-xs;
            padding-bottom: $g;
            font-size: $font-size-sm;
        }

        &-action {
            margin-top: $g;
        }
    }
}

@include media-breakpoint-up(sm) {

    .c-team {
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: -$g-sm;
        margin-right: -$g-sm;

        &__item {
            width: 50%;
            padding: 0 $g-sm;
        }
    }

    .c-card {
        &__img {
            max-width: none;
        }
    }

}

@include media-breakpoint-up(md) {

    .c-person {
        display: flex;
        margin-bottom: $o-lg;

        &__content {
            flex-shrink: 0;
            width: $column;
            margin-left: $o-lg;
        }

        &__img {
            flex-grow: 1;
            max-width: none;
        }

        &__title {
            margin: $o 0;
            font-size: $h3-font-size;
        }

        &__position {
            margin-bottom: $o;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            .c-person__content {
                margin-left: 0;
                margin-right: $o-lg;
            }
        }
    }

    .c-team {
        margin-left: -$o;
        margin-right: -$o;

        &__item {
            padding: 0 $o;
        }
    }

    .c-job {
        margin-top: $gutter;

        &__title {
            max-width: 520px;
            margin-bottom: $o-lg;
            font-size: $h3-font-size;
        }
    }

}

@include media-breakpoint-up(lg) {

    .c-team {
        &__item {
            width: 33.3334%;
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-person {
        margin-bottom: $gutter;

        &__content {
            margin-left: $gutter;
        }

        &__title {
            margin: $o-lg 0 $g;
        }

        &__position {
            margin-bottom: $o-lg;
        }

        &:nth-child(even) {
            .c-person__content {
                margin-right: $gutter;
            }
        }
    }

    .c-team {
        margin: 0 ($gutter / -2) -50px;

        &__item {
            padding: 0 $gutter / 2;
            margin-bottom: 50px;
        }
    }

    .c-card {
        &__img {
            margin-bottom: $o;
        }

        &__position {
            margin-top: 12px;
        }
    }

    .c-job {
        margin-top: 180px;

        &__title {
            max-width: 750px;
            font-size: $h2-font-size;
        }
    }

}
