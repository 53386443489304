.c-formats {
    &__title {
        max-width: 450px;
        margin-bottom: $g;
        font-size: $h4-font-size;
    }

    &__list {
        margin-top: $o-lg;
        counter-reset: format;

        &-item {
            padding-bottom: $o;
            border-bottom: 1px solid $gray-400;

            &:not(:last-child) {
                margin-bottom: $g;
            }
        }
    }
}

@include media-breakpoint-up(md) {

    .c-formats {
        &__title {
            max-width: 750px;
            margin-bottom: $o-lg;
            font-size: $h2-font-size - 1;
        }

        &__list {
            margin-top: $g-lg;

            &-item {
                padding-bottom: $g;

                &:not(:last-child) {
                    margin-bottom: $g-lg;
                }
            }
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-formats {
        &__title {
            max-width: 880px;
            margin-bottom: $o-lg;
            font-size: $h2-font-size;
        }

        &__list {
            margin-top: 180px;

            &-item {
                &:not(:last-child) {
                    margin-bottom: 140px;
                }
            }
        }
    }

}