.c-contacts {
    margin-bottom: $o-lg;

    &__row {
        display: flex;
        flex-direction: column;
        margin-bottom: -$g;
    }

    &__col {
        margin-bottom: $g;

        &-title {
            margin-bottom: $o-sm;
            font-size: $font-size-base;
            font-weight: 700;
        }
    }

    &__title {
        margin: $o-lg 0 $o;
        font-size: $h4-font-size;
        font-weight: 800;
    }
}

.c-map {
    position: relative;

    &:before {
        content: '';
        display: block;
        padding-bottom: 50%;
    }
}

#map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@include media-breakpoint-up(md) {

    .c-contacts {
        margin-bottom: $g-lg;

        &__row {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 ($o * -1) ($o-lg * -1);
        }

        &__col {
            width: 50%;
            padding: 0 $o;
            margin-bottom: $o-lg;

            &--2, &--3 {
                width: 100%;
            }

            &-title {
                margin-bottom: $g-sm;
            }
        }

        &__title {
            margin: $g-lg 0 $g;
        }
    }

    .c-map {
        &:before {
            padding-bottom: 38%;
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-contacts {
        &__row {
            margin-left: $gutter / -2;
            margin-right: $gutter / -2;
        }

        &__col {
            padding: 0 $gutter / 2;
        }

        &__title {
            margin: $gutter 0 $g;
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-contacts {
        &__col {
            width: 33.3334%;

            &--2 {
                width: 66.6667%;
            }

            &--3 {
                width: 100%;
            }
        }
    }

}