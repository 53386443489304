.c-delivery {

    &__slider {
        @extend .c-page__slider;
        margin-bottom: 80px;

        .swiper-slide {
            position: relative;

            &:before {
                content: '';
                display: block;
                padding-bottom: 35%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__list {
        padding-left: 0;
        margin: 0 (-$o-sm);
        list-style: none;

        &-item {
            display: flex;
            padding-bottom: $g-sm;
        }
    }

    &__group {
        padding-bottom: $o;

        &-title {
            margin-bottom: $g-sm;
            line-height: $headings-line-height;
            font-size: 3.6rem;
        }

        &--last {
            min-height: calc(100vh - 300px);
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin: 0 (-$g-sm);
        padding: 10px 0;
        background-color: $body-bg;
    }

    &__nav {
        padding: 0 $g-sm;
        position: relative;
        flex-grow: 1;
        overflow: hidden;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: $g-sm + 1;
            z-index: 2;
            @include gradient-x($body-bg, rgba($body-bg, 0), 77%, 100%);
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
            transform: rotate(180deg);
        }

        &-list {
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            -webkit-perspective: 1000;
            -webkit-backface-visibility: hidden;
        }

        &-item {
            width: auto;
            flex-shrink: 0;
            margin-right: 5px;

            .swiper-container-horizontal &,
            &:last-child {
                margin-right: 0;
            }
        }

        &-link {
            display: block;
            padding: 7px 12px;
            font-size: 1.4rem;
            line-height: 1.8rem;
            border-radius: 16px;
            font-weight: 500;

            &:hover {
                text-decoration: none;
            }

            &.active,
            .no-touchevents &:hover {
                background-color: $delivery;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 10px;
            height: 10px;
            margin-top: -5px;
            left: 4px;
            background-image: url(../img/svg/arrow-sm.svg);
            background-size: contain;
            transition: opacity $transition;

            &.swiper-button-disabled {
                opacity: 0;
            }
        }

        .swiper-button-prev {
            transform: rotate(180deg);
        }

        .swiper-button-next {
            left: auto;
            right: 4px;
        }
    }

    &__info {
        &-location {
            padding: $o-sm 0;
            font-size: 1.4rem;
            line-height: $headings-line-height;
        }

        &-link {
            display: none;
        }

        &-price {
            display: inline-block;
            padding: 10px 15px;
            font-size: 1.2rem;
            border: 2px solid $delivery;

            strong, b {
                margin-right: 7px;
            }
        }
    }

    &__cart-btn {
        display: none;
    }

    &__footer {
        padding: $o 0;
        font-size: 1.8rem;

        &-payment {
            line-height: $headings-line-height;

            img {
                margin-top: -2px;
                vertical-align: middle;
            }
        }

        &-call {
            margin-top: $o-md;
            font-size: 1.4rem;

            a {
                font-size: 2rem;
                font-weight: 500;

                &:hover {
                    color: $red;
                    text-decoration: none;
                }
            }

            div {
                opacity: .5;
            }
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10;
        height: 22px;
        background-color: $gray-100;
        font-size: 1.4rem;
        font-weight: 500;

        svg {
            width: 14px;
            height: 10px;
            margin-right: 7px;
            fill: $delivery;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.c-delivery-item {
    padding: $o $o-sm 0;

    &__icon {
        max-width: 220px;
        margin-bottom: $g-sm;
    }

    &__title {
        margin-bottom: $g-sm;
        font-size: 3rem;
        font-weight: 700;
        line-height: $headings-line-height;
    }

    &__description {
        margin-bottom: 0;
        font-size: 1.8rem;
    }
}

.c-delivery-banner {
    background-color: $delivery-banner-bg;
    color: $delivery-banner-color;
    display: flex;
    align-items: center;
    padding: 10px $g-sm;
    justify-content: space-between;
    position: relative;

    &__link {
        color: currentColor;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }

        &:hover,
        &:focus {
            color: currentColor;
            text-decoration: none;
        }
    }

    &__logo {
        flex-shrink: 0;
        margin-left: -10px;

        svg, img {
            width: 55px;
            height: 28px;
        }
    }

    &__text {
        font-weight: 600;
        margin-left: 16px;
        margin-right: 16px;
        line-height: 1.4;
        font-size: 12px;

        mark {
            border-radius: 12px;
            background-color: $black;
            padding: 2px 7px;
            margin-left: -7px;
            color: currentColor;
        }
    }

    &__btn {
        position: relative;
        z-index: 1;
        margin-right: -5px;
    }
}

.o-delivery-btn {
    display: inline-block;
    padding: 14px 35px;
    font-size: 1.8rem;
    font-weight: 700;
    color: $black;
    text-transform: uppercase;
    text-align: center;
    border: none;
    background-color: $delivery;
    transition: background-color $transition, color $transition;
    cursor: pointer;

    &:hover {
        background-color: $gray-800;
        color: $white;
        text-decoration: none;
    }

    &.disabled,
    &:disabled {
        color: $black;
        background-color: $gray-400;
        cursor: not-allowed;
    }

    &.o-delivery-btn--sm {
        padding: 10px 20px;
        font-size: 1.2rem;
    }
}

@media (min-width: 400px) {

    .c-delivery-banner {

        &__text {
            font-size: 14px;
        }
    }
}

@include media-breakpoint-down(lg) {

    .c-delivery-banner {

        &__btn {

            .btn {
                padding: 15px 18px;
                font-size: 1.2rem;
            }
        }
    }
}

@include media-breakpoint-down(lg) {

    .c-delivery__header {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $white;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            z-index: 1;
        }
    }

    .is-sticky {
        .c-delivery {
            &__info {
                margin-bottom: 78px;

                .c-delivery--sm & {
                    margin-bottom: 0;
                }
            }

            &__header {
                position: fixed;
                top: 90px;
                left: $gutter / 2;
                right: $gutter / 2;
                z-index: 9;
                transition: top $transition;

                &.headroom--unpinned {
                    top: 90px - 78px;
                }
            }
        }
    }

    .is-locations,
    .is-sidebar,
    .is-modal,
    .is-cart {
        .c-delivery__link {
            pointer-events: none;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba($white, .5);
            }
        }

        .c-delivery__header {
            pointer-events: none;

            &:after {
                opacity: .5;
                visibility: visible;
            }
        }

        .c-cart {
            pointer-events: auto;
        }
    }

}

@include media-breakpoint-down(sm) {

    .is-sticky {
        .c-delivery {
            &__info {
                margin-bottom: 52px;
            }

            &__header {
                top: 60px;
                left: $g-sm;
                right: $g-sm;

                &.headroom--unpinned {
                    top: 60px - 52px;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {

    .c-delivery {
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 (-$g-sm);

            &-item {
                width: 50%;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }

    .c-delivery-banner {

        &__logo {

            svg,
            img {
                width: 80px;
                height: 40px;
            }
        }
    }

    .c-delivery-item {
        &__icon {
            position: relative;

            &:before {
                content: '';
                display: block;
                padding-bottom: 100%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

}

@include media-breakpoint-up(md) {

    .c-delivery {
        &__slider {
            margin-bottom: 130px;
        }

        &__group {
            padding-bottom: $g-md;

            &-title {
                margin-bottom: $o;
                font-size: 6rem;
            }
        }

        &__header {
            top: 90px;
            padding: $o-sm $g-sm $o-sm 0;
            z-index: 2;
            margin-left: -$o;
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: $o-sm;

            &-location {
                padding: 0;
                font-size: 1.6rem;
            }

            &-price {
                margin-left: $g-sm;
                flex-shrink: 0;
                font-size: 1.4rem;
            }
        }

        &__cart {
            position: relative;
            flex-shrink: 0;

            &-btn {
                display: block;
                padding: 15px 0 15px 15px;
                background-color: $delivery;
                font-weight: 700;
                font-size: 1.4rem;
                border: none;
                cursor: pointer;
                transition: background-color $transition, color $transition;

                span {
                    display: inline-block;
                    margin-left: 15px;
                    width: 50px;
                    text-align: center;
                    border-left: 1px solid #F0F0F0;
                }
            }

            &:not(.is-disable):hover {
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    height: 15px;
                    width: 350px;
                }

                .c-delivery__cart-btn {
                    background-color: $black;
                    color: $white;
                }

                .c-cart {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                    transform: translateY(0);
                }
            }
        }

        &__footer {
            padding-bottom: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-call {
                flex-shrink: 0;
                margin-top: 0;
                margin-left: $g;
            }
        }

        &__nav {
            padding: 0 $o;

            &:before,
            &:after {
                width: $o
            }

            .swiper-button-prev {
                left: 7px;
            }

            .swiper-button-next {
                right: 7px;
            }
        }

        &__link {
            height: 30px;

            svg {
                width: 18px;
                height: 14px;
            }
        }
    }

    .c-delivery-banner {
        padding-left: $gutter / 2;
        padding-right: $gutter / 2;
    }

    .c-delivery-item {
        padding-top: $g;

        &__title {
            font-size: 3.6rem;
        }
    }

}

@include media-breakpoint-only(lg) {

    .c-delivery {
        &__list {
            &-item {
                width: 33.3334%;
            }
        }
    }

}

@include media-breakpoint-up(xl) {

    .c-delivery {
        padding-top: 145px;
        position: relative;

        &--sm {
            padding-top: 70px;
        }

        &__info {
            padding: $g-sm 0 5px;

            &-location {
                flex-grow: 1;
            }

            &-link {
                display: block;
                flex-shrink: 0;
                margin-left: $g-sm;
                font-size: 1.4rem;
                line-height: $headings-line-height;
                font-weight: 500;

                &:hover {
                    text-decoration: none;
                    color: $delivery;
                }
            }

            &-price {
                line-height: 18px;
            }
        }

        &__link {
            display: none;
        }

        &__sticky {
            position: absolute;
            top: 0;
            right: $gutter / 2 - $g-sm;
            left: $gutter / 2 - $g-sm;
            padding: 0 $g-sm;
            background-color: $gray-100;
            z-index: 8;
            transition: transform $transition;

            &.is-sticky {
                position: fixed;
                right: $gutter / 2 - $g-sm;
                left: $gutter / 2+$sidebar - $g-sm;
            }

            &.headroom--unpinned {
                transform: translateY(-67px);
            }
        }
    }

    .c-delivery-banner {
        padding: 12px 26px;

        &__logo {
            svg, img {
                width: 123px;
                height: 63px;
            }
        }

        &__text {
            margin-left: 32px;
            margin-right: 32px;
            font-size: $font-size-base;

            mark {
                border-radius: 20px;
                padding: 4px 16px;
                margin-left: -16px;
            }
        }
    }

}

@include media-breakpoint-up(hd) {

    .c-delivery {
        &__list {
            &-item {
                width: 33.3334%;
            }
        }
    }

}

@include media-breakpoint-up(laptop) {

    .c-delivery {
        &__sticky {
            right: $gutter - $g-sm;
            left: $gutter - $g-sm;

            &.is-sticky {
                right: $gutter - $g-sm;
                left: $gutter + $sidebar - $g-sm;
            }
        }
    }

}

@include media-breakpoint-up(desktop) {

    .c-delivery {
        &__slider {
            margin-bottom: 160px;
        }

        &__list {
            margin: 0 (-$g);

            &-item {
                padding: 0 25px $o-lg;
            }
        }

        &__group {
            &-title {
                font-size: 8rem;
            }
        }

        &__info {
            &-location {
                font-size: 2.2rem;
            }

            &-price {
                margin-left: $o;
            }
        }
    }

    .o-delivery-btn {
        padding: 17px 44px;
    }

}
