.c-cart {
    width: 350px;
    background-color: $white;
    z-index: 5;

    &__close {
        display: flex;
        padding: 0;
        background: none;
        border: none;
        margin: -15px 0 20px auto;
        cursor: pointer;

        svg {
            fill: currentColor;
        }

        &:hover {
            color: $red;
        }
    }

    &__main {
        position: relative;

        &-inner {
            position: relative;
            padding: 0 $o-sm;
    
            &:before,
            &:after {
                content: '';
                display: block;
            }
    
            &:before {
                height: $o-sm;
            }
    
            &:after {
                height: $o;
            }
    
            .ps__rail-y {
                right: 3px !important;
                background-color: rgba(#C4C4C4, .3);
                opacity: 1;
            }
    
            .ps__thumb-y {
                background-color: #C4C4C4;
            }
        }

        &-list {
            transition: opacity $transition;

            .is-loading & {
                opacity: .3;
                pointer-events: none;
            }
        }
    }

    &__total {
        padding: 0 $o-sm $o-sm;

        &-message {
            position: relative;
            padding: $o-sm $o-sm $o-sm 100px;
            margin-bottom: $o-sm;
            color: $white;
            font-size: 1.6rem;
            background-color: #FC573C;
            border-radius: 5px;

            &:before {
                content: '';
                display: block;
                width: 22px;
                height: 22px;
                position: absolute;
                top: -8px;
                left: 39px;
                background-color: #FC573C;
                border-radius: 4px;
                transform: rotate(45deg);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100px;
                background: url(../img/svg/delivery2.svg) no-repeat center;
                background-size: 45px auto;
            }
        }

        &-row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            line-height: $headings-line-height;

            & + & {
                margin-top: 5px;
            }
        }

        &-title {
            margin-top: 2px;
            font-size: 1.6rem;
            font-weight: 500;
        }

        &-value {
            margin-left: $o-sm;
            flex-shrink: 0;
            font-weight: 700;
            font-size: 2rem;
        }

        &:before {
            content: '';
            display: block;
            height: 1px;
            background-image: repeating-linear-gradient(to right, $gray-500, $white 1px, $white 5px);
            margin-bottom: $g-sm;
            pointer-events: none;
        }

        .o-delivery-btn {
            width: 100%;
            margin-top: $o-sm;
        }
    }

    &__empty {
        padding: 10px 30px 15px;
        display: none;

        img {
            display: block;
            margin: 0 auto 45px;
        }

        &-title {
            margin-bottom: 12px;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: $headings-line-height;
        }

        &-description {
            margin-bottom: 0;
            font-size: 1.6rem;
        }
    }

    &.is-empty {
        .c-cart {
            &__main-list,
            &__total {
                display: none;
            }

            &__empty {
                display: block;
            }
        }
    }

}

.c-cart-mobile {
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: $g-sm;
    bottom: $g-sm;
    border: none;
    border-radius: 50%;
    background-color: $delivery;
    z-index: 5;
    cursor: pointer;

    svg {
        fill: currentColor;
        transform: translateY(-2px);
    }

    span {
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -3px;
        right: -3px;
        font-size: 1.3rem;
        font-weight: 500;
        color: $white;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        background-color: $red;
    }
}

@include media-breakpoint-down(sm) {

    .c-cart {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        max-width: 100%;
        transform: translateX(100%);
        transition: transform $transition;
        z-index: 10;

        &__main {
            flex-grow: 1;

            &-inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &__total {
            flex-shrink: 0;
        }

        .is-cart & {
            transform: translateX(0);
        }
    }

}

@include media-breakpoint-up(md) {

    .c-cart {
        position: absolute;
        top: calc(100% + 15px);
        right: 0;
        border-radius: 4px;
        box-shadow: 0px 0px 1px rgba($black, .04), 0px 2px 6px rgba($black, .04), 0px 16px 24px rgba($black, .06);
        transform: translateY(-7px);
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: transform $transition, opacity $transition, visibility $transition;

        &:before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            right: 50px;
            top: 0;
            transform: translateX(50%) translateY(-50%) rotate(45deg);
            background-color: $white;
            box-shadow: 0px 0px 1px rgba($black, .04), 0px 2px 6px rgba($black, .04), 0px 16px 24px rgba($black, .06);
            pointer-events: none;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 30px;
            width: 40px;
            height: 15px;
            background-color: $white;
            pointer-events: none;
        }

        &__empty {
            padding: 45px;
        }

        &__close {
            display: none;
        }
    }

    .c-cart-mobile {
        display: none;
    }

}